import React, { Component } from "react";

import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
// import Iframe from "react-iframe";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Footer } from "../share/footer";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { FxService } from "../../service/fx.service";
import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import { TopBar } from "../share/topBar";
import { BetBtn } from "../share/bet-btn";
import { MiniService } from "../../service/mini.service";
import { SlotService } from "../../service/slot.service";

import { colors } from "@material-ui/core";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
    backgroundColor: "#424242",
  },
  paper: {
    paddingTop: 100,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomCardContent = styled(CardContent)`
  padding: 0px;
`;

const CustomButton = styled(Button)`
  width: 29%;
  font-size: 11px;
  margin: 4px;
`;

// const classes = useStyles();

interface Props {
  classes: any;
  authenticated: boolean;

  session: any;
  user: any;

  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  id : any;
  userName : any;
  userBankName: any;
  userAccountNumber: any;

  balance : number
  inBalance : number
  
  
  
}

class Coupons extends Component<Props, State> {
  divElement: any;
  static propTypes: { classes: PropTypes.Validator<object> };
  miniService = new MiniService();
  balanceService = new BalanceService();
  userService = new UserService();
  slotService = new SlotService();
  
  
  constructor(props: Props) {
    super(props);
    this.state = {
      id :  '',
      userName :  '',
      userBankName:  '',
      userAccountNumber: '',
      balance : 0,
      inBalance : 0,
      
     
  
    };
  }

  componentDidMount() {

  }

  handleRollingToBalance = () => {
  
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
        
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    const classes = this.props.classes;

    return (

      <div>
      <TopBar
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
        ></TopBar>


        <div className="contents_wrap">
          <div className="contents_box">
          <div id="title_wrap">
            <div className="title">콤프</div>
          </div>
          {/* <div className="con_box05">
            <div className="info_wrap">
                <div className="info2">주의사항</div>
                <div className="info3">
                - 출금 최소금액은 10만원부터 만원단위로 출금 가능합니다.<br/>
                - 출금은 가입하신 동일 예금주로만 가능합니다.
                </div>
            </div>
        </div> */}
        {/* <div className="con_box05">
			<div className="info_wrap">
        <div className="info2" style={{textAlign:'center'}}><span className="ww_font">내지갑</span> 
          <img src="/mo/images/ww_icon.png" height="30" style={{ paddingTop: '10px'}}  onClick={()=>this.handleUpdateInBalance()} /> 
                <input className="input3 walletBalance" id="balance_offer" readOnly  value={this.state.inBalance} />  원</div>
        </div>
		</div> */}
            <div className="con_box00">
              <table className="write_title_top" style={{    width: '100%'}}>
         
              <tr>
                  <td className="write_title">보유콤프</td>
                  <td className="write_basic">{ConverMoeny(this.props.user.rolling)} 원</td>
              </tr>

         
                <tr>
                  <td className="write_title">출금금액</td>
                  <td className="write_basic">
                    <input
                      className="input1 userID"
                      value={this.state.balance}

                      onChange={(e: any) => {
                        this.setState({ balance: e.target.value });
                      }}
                    />

                      <table style={{width: '100%'}}>
                            <tbody><tr>
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 10000 }); }} ><span className="m_btn1">1만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 50000 }); }}  ><span className="m_btn1">5만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 100000 }); }}  ><span className="m_btn1">10만원</span></a></td>                
                            </tr>            
                            <tr>
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 500000 }); }}  ><span className="m_btn1">50만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 1000000 }); }}  ><span className="m_btn1">100만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 5000000 }); }}  ><span className="m_btn1">500만원</span></a></td>
                            </tr>
                            <tr>
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 10000000 }); }}  ><span className="m_btn1">1000만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: this.state.balance + 15000000 }); }}  ><span className="m_btn1">1500만원</span></a></td>                
                                <td style={{width: '10%'}} align="center"><a onClick={() => { this.setState({ balance: 0 }); }}  ><span className="m_btn2">정정</span></a></td>
                            </tr>                
                        </tbody></table>
                  </td>
                </tr>

            
                {/* <tr>
                  <td className="write_title">계좌번호</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.banknumber}
                      onChange={(e: any) => {
                        this.setState({ banknumber: e.target.value });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="write_title">이름</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.bankowner}
                      onChange={(e: any) => {
                        this.setState({ bankowner: e.target.value });
                      }}
                    />
                    (이름은 정보와 일치해야합니다.)
                  </td>
                </tr>
                <tr>
                  <td className="write_title">환전비번</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.exchange_pw}
                      onChange={(e: any) => {
                        this.setState({ exchange_pw: e.target.value });
                      }}
                    />
                    (이름은 정보와 일치해야합니다.)
                  </td>
                </tr>
                <tr>
                  <td className="write_title">추천코드</td>
                  <td className="write_basic">
                    <input
                      className="input1"
                      value={this.state.code}
                      onChange={(e: any) => {
                        this.setState({ code: e.target.value });
                      }}
                    />
                  </td>
                </tr> */}
              </table>
            </div>
            <div className="con_box05">
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <a
                      onClick={() => this.handleRollingToBalance()}
                    >
                      <span className="m_btn3">출금신청하기</span>
                    </a>
                  </td>
            
                </tr>
              </table>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

Coupons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Coupon = withStyles(styles, { withTheme: true })(Coupons);
export default Coupon;
