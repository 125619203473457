import React, { Component, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Game } from "./main/game";
import { Reg } from "./main/reg";
import { Deposit } from "./main/deposit";
import { Even } from "./main/even";
import { Help } from "./main/help";
import { Withdraw } from "./main/withdraw";
import { Notice } from "./main/notice";
import { Note } from "./main/note";
import { MyPage } from "./main/mypage";
import { Edit } from "./main/edit";
import { Bank } from "./main/bank";
import { Coupon } from "./main/coupon";
import { InOut } from "./main/inout";

import { UserService } from "../service/user.service";
import { NotePopup } from "./share/notepopup";



const GlobalStyles = createGlobalStyle`
body{
  color: #FFC107;
  background-color: #000;
}

.body{
  color: #FFC107;
}

.MuiPaper-root {
  background-color: #000;
  color: #FFC107;
}

.popup-content {
  width: 100%;
}

.MuiToolbar-gutters{
  padding :0px
}

.popup-content {
  background: none;
} 

.react-confirm-alert-overlay {
  z-index:99999
}
`;

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;
    level: number;
    rolling: number;

    in_balance: number;
    balance: number;
    point: number;
    bankname: string;
    banknum: string;
    bankowner: string;
  };
}

export class Mobile extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        in_balance: 0,
        balance: 0,
        point: 0,
        level: 0,
        rolling: 0,
        
        bankname: "",
        banknum: "",
        bankowner: "",
      },
    };
  }

  componentDidMount() {
    if(isMobile){

      setInterval(() => this.updateUser(), 30000);
      this.updateUser();
    }
  }

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          in_balance: s.user.in_balance,
          level: s.user.level,
          rolling: s.user.rolling,
          
          balance: s.user.balance,
          point: s.user.point,
          bankname: s.user.bankname,
          banknum: s.user.banknum,
          bankowner: s.user.bankowner,
        };

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user });
        }


        if (s.noteCount > 0 ) {
          console.log(window.location.hash, s.noteCount )
          window.location.hash = '#/note'
          // /note
          // this.setState({ helpCount : s.helpCount  });
        }
      } else {
      }
    });
  };

  render() {
    if(isBrowser){
      return (<></>)
    }
    
    // require("./indexmo.css")

    return (
      <Router>
        <Routes>
    
          <Route  path="/" element={
               <Game
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,
                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();

                window.location.reload();
              }}
            />
          }>
         
          </Route>
          <Route  path="/deposit" 
          element={
            <Deposit
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}
            /> 
          }>

          </Route>
          <Route  path="/withdraw"
                    element={

            <Withdraw
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}
            /> } >
            </Route>
          


          <Route  path="/reg" element={<Reg/>}>
            
          </Route>
          
          
          <Route  path="/even" element={
                        <Even 
                        authenticated={this.props.authenticated}
                        session={this.props.session}
                        user={this.state.user}
                        tryLogin={this.props.tryLogin}
                        tryLoginOut={() => {
                          this.setState({
                            user: {
                              id: "",
                              level : 1,
                              rolling: 0,
          
                              in_balance: 0,
                              balance: 0,
                              point: 0,
                              bankname: "",
                              banknum: "",
                              bankowner: "",
                            },
                          });
                          this.props.tryLoginOut();
                          window.location.reload();
          
                        }}/>
          
          }>
          </Route>
          <Route  path="/help" element={   <Help 
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}/>}>
         
          </Route>
          <Route  path="/notice" element={    <Notice 
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}/> }>
        
          </Route>

          <Route  path="/note" element={   <Note 
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}/> }>
          
          </Route>

          <Route  path="/mypage" element={ <MyPage 
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    level : 1,
                    rolling: 0,

                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}/> }>
           
          </Route>
          <Route  path="/edit" element={ 
               <Edit 
               authenticated={this.props.authenticated}
               session={this.props.session}
               user={this.state.user}
               tryLogin={this.props.tryLogin}
               tryLoginOut={() => {
                 this.setState({
                   user: {
                     id: "",
                     level : 1,
 
         rolling: 0,
         in_balance: 0,
                     balance: 0,
                     point: 0,
                     bankname: "",
                     banknum: "",
                     bankowner: "",
                   },
                 });
                 this.props.tryLoginOut();
                 window.location.reload();
 
               }}/>
          }>
         
          </Route>

          <Route  path="/Bank" element={   <Bank 
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    rolling: 0,
                    level : 1,
                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
                window.location.reload();

              }}/>}>
          
          </Route>
          
          <Route  path="/Coupons" element={
                   <Coupon
                   authenticated={this.props.authenticated}
                   session={this.props.session}
                   user={this.state.user}
                   tryLogin={this.props.tryLogin}
                   tryLoginOut={() => {
                     this.setState({
                       user: {
                         id: "",
                         level : 1,
                         rolling: 0,
     
                         in_balance: 0,
                         balance: 0,
                         point: 0,
                         bankname: "",
                         banknum: "",
                         bankowner: "",
                       },
                     });
                     this.props.tryLoginOut();
                     window.location.reload();
     
                   }}/>
          }>
     
          </Route>

          <Route  path="/inout" element={
                 <InOut
                 authenticated={this.props.authenticated}
                 session={this.props.session}
                 user={this.state.user}
                 tryLogin={this.props.tryLogin}
                 tryLoginOut={() => {
                   this.setState({
                     user: {
                       id: "",
                       level : 1,
                       rolling: 0,
   
                       in_balance: 0,
                       balance: 0,
                       point: 0,
                       bankname: "",
                       banknum: "",
                       bankowner: "",
                     },
                   });
                   this.props.tryLoginOut();
                   window.location.reload();
   
                 }}/>
          }>
       
          </Route> 
          

        </Routes>
        
        {this.state?.user?.id != null && this.state?.user?.id !== '' && <NotePopup
            SetCookie={this.props.SetCookie}
            GetCookie={this.props.GetCookie}
        ></NotePopup>}

        <GlobalStyles />
      </Router>
    );
  }
}
