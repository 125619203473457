import React, { Component, useState } from "react";

import MailIcon from "@material-ui/icons/Mail";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import Pagination from "@material-ui/lab/Pagination";

import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TopBar } from "../share/topBar";
import { Footer } from "../share/footer";
import { UserService } from "../../service/user.service";
import { ConvertDate } from "../../utility/help";

// import { getData, getFxData, getFxNowData } from "../../help/utils";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },

  paper: {
    marginTop: theme.spacing(15),
    display: "flex",
  },
 
});


const NewExpansionPanel = styled(ExpansionPanel)({
  borderBottom: "1px solid #9E9E9E",
});

const NewExpansionPanelSummary = styled(ExpansionPanelSummary)({
  border: 0,
  borderRadius: 3,
  color: "white",
  padding: "none",
});

export enum EvenView {
  none = "none",
  write = "write",
  view = "view",
  detail = "detail",
}


interface Props {
  classes: any;
  authenticated: boolean;

  session: any;
  user: any;

  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

}

interface State {
  mode : string 

  notices : any
  detail : any
}

export class note extends Component<Props, State> {
  userService = new UserService();

  static propTypes: { classes: PropTypes.Validator<object> };

  constructor(props: Props) {
    super(props);
    this.state = {
      mode : EvenView.view,
      notices : [],
      detail : {},

    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  }

  handleReadNote = (id : string) => {
    this.userService.do_read_note(id).then((data: any) => {});
  };


  render() {
    //const [expanded, setExpanded] = React.useState<string | false>(false);

    let notices = this.state.notices;
    return (
          
      <div>
        <TopBar
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
          ></TopBar>


          <div className="contents_wrap">
            <div className="contents_box">
            <div id="title_wrap">
              <div className="title">공지사항</div>
            </div>
              <div className="con_box05">

                {
                  // this.state.mode === EvenView.view && (
                    <table  style={{width : '100%'}}>
                      <tbody>
                      <tr>
                          <td className="list_title1">제목</td>
                          <td className="list_title1" style={{width : '30%'}}>작성일</td>
                      </tr>
      
                      {
                        this.state.notices.map((row: any) => (
                        <tr onClick={()=>{
                          this.handleReadNote(row._id)
                          this.setState({detail : row, mode :  EvenView.detail})}}>
                          <td className="list2">{row.title}</td>
                          <td className="list2" style={{width : '30%'}}>{ConvertDate(row.regDate)}</td>
                        </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  // )
                }

                {
                  this.state.mode === EvenView.detail && (
                      <table  className="write_title_top" style={{width : '100%'}}>
                        <tbody>
                        <tr>
                          <td className="view1">{this.state.detail.title}</td>
                        </tr>
                        <tr>
                          <td className="view1 font03">
                            <span className="view_box">글쓴이</span>운영자 &nbsp; <span className="view_box">작성일</span> {ConvertDate(this.state.detail.regdate)}
                          </td>
                        </tr>
                        <tr>
                          <td className="view2">
                            <div dangerouslySetInnerHTML={{ __html: this.state.detail.contents }}></div>
                          </td>
                        </tr>
                        </tbody>
                    </table>

                    )
                }
                
                
                {
                  this.state.mode === EvenView.detail && (
                    <div className="con_box10">
                      <table style={{width : '100%'}}>
                      <tbody>
                      <tr>
                        <td style={{width : '10%'}}>
                          <a  onClick={()=>{this.setState({mode :  EvenView.view})}}><span className="m_btn2">목록</span></a>
                        </td>
                      </tr>
                      </tbody>
                      </table>
                    </div>
                )
                }


            
              </div>

            </div>
          </div>

          <Footer />
        </div>
    );
  }
}

note.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Note = withStyles(styles, { withTheme: true })(note);
export default Note;

// export default withStyles(styles)(Game);

// export default withStyles(useStyles)(Game);
