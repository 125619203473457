import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../../utility/help";

import { BalanceService } from "../../service/balance.service";
import { SlotService } from "../../service/slot.service";
import { SubMenu } from "./submenu";


interface Props {
  children: any;
  
  user?: any;
  handleClose?: () => any;
  handleActive?: (active:string) => any;

}

interface State {
  balance: string;
  point: string;
}

export class Bank extends Component<Props, State> {
  balanceService = new BalanceService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: '0',
      point: '0',
    };

  }

  
  handleChangeToBalance = () => {
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                

              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) < 10000) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point )
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {


    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "#000",
          border: "none",
          width: "none",
        }}
        onClose={()=> this.props?.handleClose?.()}
      >
          <div  id="fade_2"  className="slideDown popup_none popup_content" data-popup-initialized="true" aria-hidden="false" role="dialog" style={{opacity: 1, visibility: 'visible', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle', overflowY : 'auto', height : '100%'}}>
          <div className="popup_wrap">
            <div className="close_box">
              <a href="#" className="fade_1_close" onClick={()=> this.props?.handleClose?.()}><img src="/web/images/popup_close.png" /></a>
            </div>
            <div className="popupbox">

              <div id="popuptab_cont2" className="popuptab_cont">
        
              <div className="title1">금고</div>
                <div className="contents_in">
                  {/* <div className="con_box00">
                    <div className="info_wrap">
                      <div className="info2">
                        주의사항
                      </div>
                      <div className="info3">
                        - 입금 최소 1만원부터 가능하며 입금전 꼭! 본사의 충전계좌 확인 후 입금바랍니다.<br />
                        - 수표 및 토스입금시 충전처리가 불가합니다.
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="con_box10">
                    <div className="info_wrap">
                      <div className="info2" style={{textAlign:"center"}}>
                        <li><a onClick={() => { this.handleAskToAccount() }}><span className="btn3_1">계좌문의</span>

                        <span style={{fontSize: 18}}>계좌확인은 고객센터에서 본인이 보낸 글 밑에 답변 있습니다(새로고침 누름)</span>
                        
                        </a></li>

                      </div>
                    </div>
                  </div> */}


                  <div className="con_box10">
                    <div className="info_wrap">
                      <div className="info2" style={{textAlign:"center"}}>
                        <span className="ww_font">보유머니 {ConverMoeny(this.props.user.balance)} 원</span> {" -  "}
                        <span className="ww_font">금고머니 {ConverMoeny(this.props.user.point)}  원</span>
                      </div>
                    </div>
                  </div>
                
            
                  <div className="con_box10">
                    <table  className="write_title_top">
                    <tbody>
            
                    <tr>
                      <td  style={{height:"5px"}}>
                      </td>
                    </tr>
                 
                  
                    <tr>
                      <td className="write_title">입금금액</td>
                      <td className="write_td"></td>
                      <td className="write_basic"><input className="input1" id="accept_amount" name="accept_amount" placeholder="0" value={this.state.balance} onChange={(e) => this.setState({ balance: `${Number(e.target.value)}`,})}/>
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 10000}`,});}} style={{paddingLeft : 5}}><span className="btn1_2">1만원</span></a>
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 50000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">5만원</span></a>
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 100000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">10만원</span></a> 
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 500000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">50만원</span></a> 
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 1000000}`})}}  style={{paddingLeft : 5}}><span className="btn1_2">100만원</span></a> 
                        <a onClick={() => {this.setState({balance: `${Number(this.state.balance) + 5000000}`});}}  style={{paddingLeft : 5}}><span className="btn1_2">500만원</span></a> 
                        <a onClick={this.handleChangeToBalance}  style={{paddingLeft : 5}}><span className="btn1_1">입금하기</span></a>
                      </td>
                    </tr>  
            
                    <tr>
                      <td  style={{height:"5px"}}>
                      </td>
                    </tr>
                 
                  
                    <tr>
                      <td className="write_title">출금금액</td>
                      <td className="write_td"></td>
                      <td className="write_basic"><input className="input1" id="accept_amount" name="accept_amount" placeholder="0" value={this.state.point} onChange={(e) => this.setState({ point: `${Number(e.target.value)}`,})}/>
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 10000}`,});}} style={{paddingLeft : 5}}><span className="btn1_2">1만원</span></a>
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 50000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">5만원</span></a>
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 100000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">10만원</span></a> 
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 500000}`,});}}  style={{paddingLeft : 5}}><span className="btn1_2">50만원</span></a> 
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 1000000}`});}}  style={{paddingLeft : 5}}><span className="btn1_2">100만원</span></a> 
                        <a onClick={() => {this.setState({point: `${Number(this.state.point) + 5000000}`});}}  style={{paddingLeft : 5}}><span className="btn1_2">500만원</span></a> 
                        <a  onClick={this.handleChangeToPoint}  style={{paddingLeft : 5}}><span className="btn1_1">출금하기</span></a>
                     </td>
            
                     </tr>

                    </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
     
      </Popup>
    );
  }
}
